import { inject } from '@angular/core';
import { HttpRequest, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { IUser } from '../models/model';

export const JwtInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const authService = inject(AuthenticationService);
    const currentUser: IUser | null = authService.currentUser.value;
    if (currentUser) {
        const headers: Record<string, string> = {
            Authorization: `Bearer ${currentUser.token}`
        };

        const _req = request.clone({
            setHeaders: headers
        });

        return next(_req);
    }


    return next(request)
}
