import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { logGuard } from './core/guards/log.guard';
import { loggedInGuard } from './core/guards/logged-in.guard';
import { ayookinsRoutes } from './layouts/ayookins/ayookins.routes';

export const routes: Routes = [
  {
    path: '',
    canActivate: [loggedInGuard],
    loadComponent: () => import('./layouts/ayoocons/main-layout/components/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'ayookins-family-health-game',
    loadComponent: () => import('./layouts/ayookins/ayookins.component').then((m) => m.AyookinsComponent),
    children: ayookinsRoutes
  },
  {
    path: 'login',
    canActivate: [loggedInGuard],
    loadComponent: () => import('./features/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'forgot-password',
    canActivate: [loggedInGuard],
    loadComponent: () => import('./features/forgot-password/forgot-password.component').then((m) => m.ForgotPasswordComponent),
  },
  {
    path: 'signup',
    canActivate: [loggedInGuard],
    loadComponent: () => import('./features/signup/signup.component').then((m) => m.SignupComponent),
  },
  {
    path: 'auth',
    canActivate: [loggedInGuard],
    loadComponent: () => import('./features/google-auth/google-auth.component').then((m) => m.GoogleAuthComponent),
  },
  {
    path: 'create-profile',
    loadComponent: () => import('./features/create-profile/create-profile.component').then((m) => m.ProfileComponent),
  },
  {
    path: 'signup-success',
    loadComponent: () => import('./features/signup/components/success/success.component').then((m) => m.SuccessComponent),
  },
  {
    path: 'auth/signup-success',
    loadComponent: () => import('./features/google-auth/components/success/success.component').then((m) => m.GoogleAuthSuccessComponent),
  },
  {
    path: 'auth/create-profile',
    loadComponent: () => import('./features/google-auth/components/google-signup/profile.component').then((m) => m.GoogleSignupComponent),
  },
  {
    path: 'select-game',
    canActivate: [authGuard],
    loadComponent: () => import('./features/select-game/select-game.component').then((m) => m.SelectGameComponent),
  },
  {
    path: 'ayoocons',
    canActivate: [authGuard],
    loadChildren: () => import('../app/layouts/ayoocons/dashboard-layout/dashboard-layout.routes').then((m) => m.dashboardLayoutRoutes),
  },
];