import { Routes } from '@angular/router';
import { loggedInGuard } from '../../core/guards/logged-in.guard';
import { authGuard } from '../../core/guards/auth.guard';

export const ayookinsRoutes: Routes = [{
    path: '',
    canActivate: [loggedInGuard],
    loadComponent: () => import('./ayookins-landing/ayookins-landing.component').then((m) => m.AyookinsLandingComponent)
}, {
    path: 'onboarding',
    canActivate: [authGuard],
    loadComponent: () => import('./dashboard/components/onboard/onboard.component').then((m) => m.OnboardComponent)
}, {
    path: 'dashboard',
    canActivate: [authGuard],
    loadComponent: () => import('./dashboard/dashboard.component').then((m) => m.DashboardComponent)
},
{
    path: 'profile/:id',
    canActivate: [authGuard],
    loadComponent: () => import('./dashboard/components/profile/profile.component').then((m) => m.ProfilepageComponent)
},
{
    path: 'edit-profile/:id',
    canActivate: [authGuard],
    loadComponent: () => import('./dashboard/components/profile/components/edit-profile/edit-profile.component').then((m) => m.EditprofileComponent)
},
{
    path: 'quest-update/:id',
    canActivate: [authGuard],
    loadComponent: () => import('./dashboard/components/quest-update/quest-update.component').then((m) => m.QuestUpdateComponent)
},
{
    path: 'challenge/:id',
    loadComponent: () => import('./dashboard/components/quests/quests.component').then((m) => m.QuestsComponent)
},
{
    path: 'coupons',
    loadComponent: () => import('./dashboard/components/coupons/coupons.component').then((m) => m.CouponsComponent),
},
{
    path: 'recommendations',
    loadComponent: () => import('./dashboard/components/quest-update/components/recommendations/recommendations.component').then((m) => m.RecommendationsComponent),
},
{
    path: 'stats-graphs/:userId/:statId',
    loadComponent: () => import('./dashboard/components/profile/components/stats-graphs/stats-graphs.component').then((m) => m.StatsGraphsComponent),
},
{
    path: 'account-settings/:id',
    loadComponent: () => import('./dashboard/components/menu/account-settings/account-settings.component').then((m) => m.AccountSettingsComponent),
}
]
