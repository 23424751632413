import { inject } from '@angular/core';
import { HttpRequest, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';

export const ErrorInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const toastService = inject(ToastrService)
    const authService = inject(AuthenticationService)

    return next(request).pipe(
        tap(),
        catchError(err => {
        if (err.status === 401) {
            toastService.error(err.error.message)
            authService.logout()
        } else if (err.status === 500) {
            toastService.error(err.error.error, 'Internal Server Error')
        }
        return throwError(err);
    }));
}
